import React, { useState } from 'react';
import styles from './style.module.css';
import GroupSvg from '../../assets/SVGS/GroupSvg';
import LogoSvg from '../../assets/SVGS/LogoSvg';
import homeVideo from '../../assets/HeadVideo.mp4';
import aboutUsImg from '../../assets/about-us.png';
import blogItem from '../../assets/blogitem.png';
import refrigerators from '../../assets//refrigerators.png';
import contactUsImg from '../../assets/contact-us.png';
import productsImg from '../../assets/productsImg.png';
import { NavLink, useLocation } from 'react-router-dom';
const Header = () => {
    // const [backgroundImg, setBackgroundimg] = useState('')
    const location = useLocation();
    const path = location.pathname.slice(1);
    if (path.includes('blogItem')) {
        console.log(path);
    }
    // const imageSrc = '../../assets/' + path + '.png'
    // console.log(process.env.REACT_APP_STORAGE_URL + '/about-us.png');
    // console.log(`../../assets/about-us.png`);
    // console.log(styles.active)

    const getBackgroundImage = (path) => {
        switch (path) {
            case 'about-us':
                return aboutUsImg;
            case 'contacts':
                return contactUsImg;
            case '':
                return 'none';
            case 'blog':
                return contactUsImg;
            case 'refrigerators':
                return refrigerators;
            case 'packaging':
                return aboutUsImg;
            case 'brands':
                return aboutUsImg;
            case 'ecosystem':
                return aboutUsImg;
            case 'products':
                return productsImg;
            case path.includes('blogItem'):
                return blogItem;
            default:
                return blogItem;
        }
    };
    const backgroundImage = getBackgroundImage(path);
    //   console.log(backgroundImage);
    return (
        <header className={styles.header}>
            {!path.length && (
                <video
                    autoPlay={true}
                    controls={false}
                    className={styles.headVideo}
                >
                    <source src={homeVideo} type="video/mp4" />
                </video>
            )}
            <div
                className={styles.headerArea + ' ' + styles.homeHeaderArea}
                style={{
                    backgroundImage: path.length
                        ? `url(${backgroundImage})`
                        : 'unset',
                }}
            >
                <div className={styles.headerTopContent}>
                    <NavLink to="/" className={styles.logoContent}>
                        <div className={styles.logo}>
                            <LogoSvg />
                        </div>
                        <GroupSvg className={styles.groupSvg} />
                    </NavLink>
                    <div className={styles.navContent}>
                        <div className={styles.linksBox}>
                            <NavLink
                                to={'about-us'}
                                className={styles.headerLinks}
                                activeClass={styles.active}
                            >
                                ABOUT US
                            </NavLink>
                            <NavLink
                                to={'packaging'}
                                className={styles.headerLinks}
                                activeClass={styles.active}
                            >
                                Packaging
                            </NavLink>
                            <NavLink
                                to={'refrigerators'}
                                className={styles.headerLinks}
                                activeClass={styles.active}
                            >
                                Refrigerators
                            </NavLink>
                            <NavLink
                                to={'ecosystem'}
                                className={styles.headerLinks}
                                activeClass={styles.active}
                            >
                                BRANDS
                            </NavLink>
                            <NavLink
                                to={'blog'}
                                className={styles.headerLinks}
                                activeClass={styles.active}
                            >
                                BLOG
                            </NavLink>
                            <NavLink
                                to={'contact-us'}
                                className={styles.headerLinks}
                                activeClass={styles.active}
                            >
                                CONTACTS
                            </NavLink>
                        </div>
                        <div className={styles.langbox}>
                            <span>HY</span>
                            <span>EN</span>
                            <span>RU</span>
                        </div>
                        <div className={styles.rightContent}>
                            <div className={styles.getInTouch}>
                                GET IN TOUCH
                            </div>
                            <span className={styles.ractangle}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="2"
                                    height="17"
                                    viewBox="0 0 2 18"
                                    fill="none"
                                >
                                    <path
                                        d="M0.606934 0.459473V17.4595"
                                        stroke="white"
                                        stroke-opacity="0.3"
                                    />
                                </svg>
                            </span>
                            <span className={styles.burgerMenuBtn}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="16"
                                    viewBox="0 0 22 16"
                                    fill="none"
                                >
                                    <path
                                        d="M0.608401 0.927347C0.609209 0.488841 0.964916 0.133789 1.40342 0.133789H20.598C21.0371 0.133789 21.3931 0.489733 21.3931 0.928812C21.3931 1.36789 21.0371 1.72383 20.598 1.72383H1.40342C0.963772 1.72383 0.607591 1.367 0.608401 0.927347Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M0.608401 7.92735C0.609209 7.48884 0.964916 7.13379 1.40342 7.13379H20.598C21.0371 7.13379 21.3931 7.48973 21.3931 7.92881C21.3931 8.36789 21.0371 8.72383 20.598 8.72383H1.40342C0.963772 8.72383 0.607591 8.367 0.608401 7.92735Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M0.608401 14.9889C0.609209 14.5504 0.964916 14.1953 1.40342 14.1953H20.598C21.0371 14.1953 21.3931 14.5513 21.3931 14.9903C21.3931 15.4294 21.0371 15.7854 20.598 15.7854H1.40342C0.963772 15.7854 0.607591 15.4285 0.608401 14.9889Z"
                                        fill="white"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
                {!path?.length ? (
                    <div className={styles.headerMainContent}>
                        <div className={styles.discoverFreshness}>
                            <h1>Taste Freshness Quality .</h1>
                        </div>
                        <div className={styles.headerMainContentRight}>
                            <p>
                                From our picturesque orchards in Armenia,
                                <br />
                                cultivating over a million trees, to <br />
                                implementing advanced technologies ensuring{' '}
                                <br />
                                freshness and quality, we redefine excellence in
                                fruit production.
                            </p>
                            <NavLink
                                to={'about-us'}
                                className={styles.aboutUsBtn}
                            >
                                <span>About us</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="12"
                                    viewBox="0 0 16 12"
                                    fill="none"
                                >
                                    <path
                                        d="M15.5303 6.53033C15.8232 6.23744 15.8232 5.76256 15.5303 5.46967L10.7574 0.6967C10.4645 0.403807 9.98959 0.403807 9.6967 0.6967C9.40381 0.989593 9.40381 1.46447 9.6967 1.75736L13.9393 6L9.6967 10.2426C9.40381 10.5355 9.40381 11.0104 9.6967 11.3033C9.98959 11.5962 10.4645 11.5962 10.7574 11.3033L15.5303 6.53033ZM-6.55671e-08 6.75L15 6.75L15 5.25L6.55671e-08 5.25L-6.55671e-08 6.75Z"
                                        fill="white"
                                    />
                                </svg>
                            </NavLink>
                        </div>
                    </div>
                ) : (
                    <div
                        className={styles.headerMainContent}
                        style={{ height: '-webkit-fill-available' }}
                    >
                        {window.innerWidth > 768 ? (
                            <h2
                                className={styles.pageTitle}
                                style={{
                                    fontSize:
                                        path
                                            .replace(/[^a-zA-Z ]/g, ' ')
                                            .includes('blogItem') && '54px',
                                    textTransform:
                                        path
                                            .replace(/[^a-zA-Z ]/g, ' ')
                                            .includes('blogItem') && 'none',
                                }}
                            >
                                {path
                                    .replace(/[^a-zA-Z ]/g, ' ')
                                    .replace('blogItem', '')}
                            </h2>
                        ) : (
                            <h2
                                className={styles.pageTitle}
                                style={{
                                    fontSize:
                                        path
                                            .replace(/[^a-zA-Z ]/g, ' ')
                                            .includes('blogItem') && '28px',
                                    textTransform:
                                        path
                                            .replace(/[^a-zA-Z ]/g, ' ')
                                            .includes('blogItem') && 'none',
                                }}
                            >
                                {path
                                    .replace(/[^a-zA-Z ]/g, ' ')
                                    .replace('blogItem', '')}
                            </h2>
                        )}
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header;
