import React from 'react';
import styles from './style.module.css';
import ReadtToTake from '../../components/ReadyToTake/ReadyToTake';
import img1 from '../../assets/refimg1.png';
import img2 from '../../assets/refimg2.png';
import img3 from '../../assets/refimg3.png';

const Refrigerators = () => {
    return (
        <>
            <section className={styles.refrigeratorsSection}>
                <div className={styles.content}>
                    <div className={styles.contentLeft}>
                        <h5 className={styles.contentLeftTitle}>
                            The key to the freshness and quality of our fruits
                        </h5>
                        <p className={styles.contentLeftText}>
                            At TradeBerry, our commitment goes beyond providing
                            high-quality fruits; we strive to offer optimal
                            storage conditions to maintain freshness and taste
                            quality. Our long-standing partnership with Geerlofs
                            Refrigeration, a global leader in refrigeration
                            equipment supply, plays a pivotal role in achieving
                            this goal.
                        </p>
                        <p className={styles.contentLeftText}>
                            Technologies and advantages
                        </p>
                        <h5 className={styles.contentLeftTitle}>
                            Atmosphere control station (ACS)
                        </h5>
                        <p className={styles.contentLeftText}>
                            Our refrigeration chambers are equipped with ACS for
                            automatic atmosphere control, ensuring ideal storage
                            conditions for various fruit types.
                        </p>
                        <h5 className={styles.contentLeftTitle}>
                            Ultra-Low Oxygen (ULO) technology
                        </h5>
                        <p className={styles.contentLeftText}>
                            Implementing ULO technology in our refrigerators
                            extends the shelf life of fruits, preserving their
                            vitamin content and distinctive flavor.
                        </p>

                        <img src={img3} />
                        <h5 className={styles.contentLeftTitle}>
                            Ethylene converters
                        </h5>
                        <p className={styles.contentLeftText}>
                            Our facilities incorporate efficient ethylene
                            converters that regulate and control the ripening
                            process of fruits, ensuring uniform and optimal
                            quality.
                        </p>
                        <h5 className={styles.contentLeftTitle}>
                            Nitrogen injection (N2)
                        </h5>
                        <p className={styles.contentLeftText}>
                            The nitrogen injection system guarantees rapid and
                            controlled reduction of oxygen levels, preventing
                            undesirable changes in the quality of our produce.
                        </p>
                        <h5 className={styles.contentLeftTitle}>
                            Collaboration for quality!
                        </h5>
                        <p className={styles.contentLeftText}>
                            TradeBerry's collaboration with Geerlofs
                            Refrigeration is a guarantee of utilizing
                            cutting-edge technologies in fruit storage. We are
                            confident that through these innovative solutions,
                            we provide our customers with the freshest, most
                            delicious, and most nutritious fruits.
                        </p>
                    </div>
                    <div className={styles.contentRight}>
                        <h5 className={styles.contentLeftTitle}>
                            Packaging is the science, art and technology which
                            ensures the safe delivery of a product to an
                            ultimate customer in safe and sound condition
                        </h5>

                        <img
                            src={img1}
                            className={styles.contentRightFirstImg}
                        />
                        <div className={styles.flexImgBlock}>
                            <img
                                src={img2}
                                className={styles.contentRightSecondImg}
                            />
                            <img
                                src={img3}
                                className={styles.contentRightThirdImg}
                            />
                        </div>
                    </div>
                </div>
                <ReadtToTake />
            </section>
        </>
    );
};

export default Refrigerators;
