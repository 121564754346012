import './assets/fonts/AeonikTRIAL-Bold.otf';
import './assets/fonts/AeonikTRIAL-BoldItalic.otf';
import './assets/fonts/AeonikTRIAL-Light.otf';
import './assets/fonts/AeonikTRIAL-Regular.otf';
import './assets/fonts/AeonikTRIAL-RegularItalic.otf';
import "./fonts.css";
import './App.css';
import AppRouter from './components/AppRouter/AppRouter';

function App() {
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
