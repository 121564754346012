const Fruit1 = () => {
    return (
        <>
            <path d="M195.212 127.914C193.217 142.294 192.15 163.275 140.591 186.342C198.523 166.309 198.002 135.653 195.824 117.151C195.786 116.828 196.185 116.659 196.405 116.898C219.222 141.673 208.385 179.727 192.291 193.105C155.015 224.091 111.129 222.408 95.7801 219.738C85.3755 217.034 74.3046 216.147 62.2426 201.282C56.51 198.075 44.5574 184.682 42.607 156.759C41.5924 132.156 58.7027 95.0391 97.187 76.8067C120.101 65.1589 153.673 60.3058 172.78 73.4448C182.979 79.4488 195.777 100.917 195.212 127.914Z" fill="#191D29" />
            <path d="M228.127 95.5611C225.761 95.9577 219.961 98.1489 212.085 103.829L203.917 109.094L205.326 107.047L199.996 109.858C202.595 101.8 221.797 89.272 224.74 86.4493C225.716 85.5137 226.545 85.6817 227.069 86.0884C230.339 88.6242 236.057 94.125 236.051 97.0202C236.122 97.5589 236.364 98.5181 233.914 98.6416C232.062 98.7745 230.494 95.1645 228.127 95.5611Z" fill="#191D29" />
            <path d="M212.443 103.525L204.523 108.658L205.767 106.663C202.47 108.451 195.886 112.093 195.924 112.356C213.102 133.338 228.535 146.362 254.114 150.939C257.521 151.311 269.962 153.292 280.453 145.318C267.123 106.198 242.966 101.541 233.707 100.255C229.498 99.921 219.354 100.107 212.443 103.525Z" fill="#191D29" />
            <path data-stroke="true" d="M183.369 124.207C181.374 138.587 180.307 159.568 128.748 182.635C186.68 162.602 186.158 131.946 183.981 113.444C183.943 113.121 184.342 112.952 184.562 113.19C207.379 137.966 196.541 176.02 180.447 189.398C143.171 220.384 99.2859 218.701 83.9368 216.031C73.5322 213.327 62.4614 212.44 50.3993 197.575C44.6668 194.368 32.7141 180.975 30.7637 153.052C29.7491 128.449 46.8594 91.332 85.3437 73.0997C108.258 61.4519 141.83 56.5988 160.937 69.7377C171.136 75.7418 183.934 97.2105 183.369 124.207Z" strokeOpacity="0.5" strokeWidth="2.7115" />
            <path data-stroke="true" d="M216.284 91.8541C213.917 92.2507 208.117 94.4418 200.241 100.122L192.074 105.387L193.482 103.34L188.152 106.151C190.751 98.0935 209.953 85.565 212.896 82.7423C213.872 81.8067 214.701 81.9747 215.225 82.3814C218.496 84.9171 224.213 90.418 224.207 93.3132C224.278 93.8518 224.52 94.811 222.07 94.9346C220.218 95.0674 218.65 91.4575 216.284 91.8541Z" strokeOpacity="0.5" strokeWidth="2.7115" />
            <path data-stroke="true" d="M200.599 99.8175L192.68 104.951L193.923 102.956C190.626 104.744 184.042 108.386 184.08 108.649C201.259 129.631 216.692 142.655 242.271 147.232C245.677 147.604 258.118 149.585 268.61 141.611C255.28 102.491 231.123 97.8342 221.863 96.5478C217.654 96.2139 207.51 96.4004 200.599 99.8175Z" strokeOpacity="0.5" strokeWidth="2.7115" />
        </>
    );
}

export default Fruit1;