import React from 'react';
import styles from './style.module.css';
import Accordion from './components/Accordion/Accordion';

const Community = () => {
    return (
        <section className={styles.communitySection}>
            <p className={styles.communityConentTitle}>TRADEBERRY COMMUNITY</p>
            <div className={styles.communityConent}>
                <div className={styles.communityConentLeft}>
                    <h1 className={styles.title}>FAQ - TradeBerry</h1>
                    <h1 className={styles.mobileTitle}>FAQ - TradeBerry</h1>
                </div>
                <div className={styles.communityConentRight}>
                    <Accordion />
                </div>
            </div>
        </section>
    );
};

export default Community;
