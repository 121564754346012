import React from 'react';
import styles from "./style.module.css"

const StayInKnowBox = () => {
    return (
        <div className={styles.stayInKnowContentBox}>
            <div className={styles.boxTop}>
                <h3 className={styles.boxTitle}>Stay in the know!</h3>
                <p className={styles.boxText}>Join our community today and stay<br></br> up-to-date with the latest industry<br></br> news and trends.</p>
            </div>
            <div className={styles.boxButtom}>
                <form>
                    <div className={styles.formBox}>
                        <div className={styles.inputBox}>
                            <label for="email">Your email address</label>
                            <input type="email" id="email" name="email" placeholder='yourname@email.com*' />
                        </div>
                        <div className={styles.inputBoxCheckbox}>
                            <input type="checkbox" id="PrivacyPolicy" name="PrivacyPolicy" />
                            <label for="PrivacyPolicy">By subscribing to the newsletter, I agree to the TradeBerry Privacy Policy</label>
                        </div>
                        <div className={styles.btnBox}>
                            <button className={styles.formButton}>
                                Subscribe for Newsletter
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
                                    <path d="M15.5303 7.03033C15.8232 6.73744 15.8232 6.26256 15.5303 5.96967L10.7574 1.1967C10.4645 0.903807 9.98959 0.903807 9.6967 1.1967C9.40381 1.48959 9.40381 1.96447 9.6967 2.25736L13.9393 6.5L9.6967 10.7426C9.40381 11.0355 9.40381 11.5104 9.6967 11.8033C9.98959 12.0962 10.4645 12.0962 10.7574 11.8033L15.5303 7.03033ZM-6.55671e-08 7.25L15 7.25L15 5.75L6.55671e-08 5.75L-6.55671e-08 7.25Z" fill="white"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default StayInKnowBox;