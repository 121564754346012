import React, { useCallback, useEffect, useRef, useState  } from 'react';
import styles from "./style.module.css";
import Mars from "../../assets/Mars.png";
import Kundig from "../../assets/kung.png";
import Nestle from "../../assets/nestle.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import ScaleContent from './components/ScaleContent';

const Ecosystem = () => {
    let brands = [
        {
            id:1, 
            name: "Mars",
            barndsAbout: {
                title: 'What is Lorem Ipsum?',
                text: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing"
            },
            whyUse: {
                title:'Why do we use it?',
                text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
            },
            img:Mars
        },
        {
            id:2, 
            name: "Kundig",
            barndsAbout: {
                title: 'What is Lorem Ipsum?',
                text: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing"
            },
            whyUse: {
                title:'Why do we use it?',
                text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
            },
            img:Kundig
        },
        {
            id:3, 
            name: "Nestle",
            barndsAbout: {
                title: 'What is Lorem Ipsum?',
                text: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing"
            },
            whyUse: {
                title:'Why do we use it?',
                text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
            },
            img:Nestle
        }
    ]
    // const [style, setStyle] = useState({
    //     contentScaleSize: 1,
    //   });
    //   useEffect(() => {
    //     const handleScroll = () => {
    //       const scrollTop = window.scrollY;
    //       const minScalSize = 0.300;
    //       const contentScaleSize = Math.max(0.3, 1 - scrollTop / 500);
    
    //       setStyle({
    //         contentScaleSize: contentScaleSize,
    //       });
    //     };
    
    //     window.addEventListener('scroll', handleScroll);
    
    //     return () => {
    //       window.removeEventListener('scroll', handleScroll);
    //     };
    //   }, []); // Empty dependency array to run the effect only once
    const sliderRef = useRef(null);
  const debounce = (func, wait, immediate) => {
    let timeout;
    return function() {
      const context = this, args = arguments;
      const later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  const slickHandleWheelEvent = (e, slickInstance, slickIsAnimating) => {
    if (!slickIsAnimating) {
      const direction = Math.abs(e.deltaX) > Math.abs(e.deltaY) ? e.deltaX : e.deltaY;

      if (direction > 0) {
        slickInstance.slickNext();
      } else {
        slickInstance.slickPrev();
      }
    }
  };

  const slickHandleWheelEventDebounced = debounce(
    slickHandleWheelEvent,
    100,
    true
  );

 
  // useEffect(() => {
  //   sliderRef.current.slickGoTo(0);
  // }, []);

    const settings = {
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // variableWidth: true,
        vertical: true, 
        swipeToSlide: true,
        beforeChange: (current, next) => {
          if (next > current && sliderRef.current && sliderRef.current.innerSlider) {
            const currentSlide = sliderRef.current.innerSlider.list.querySelector('.slick-current');
            if (currentSlide) {
              currentSlide.style.transform = 'scale(0.5)';
            }
          }
        },
        afterChange: () => {
          if (sliderRef.current && sliderRef.current.innerSlider) {
            const currentSlide = sliderRef.current.innerSlider.list.querySelector('.slick-current');
            if (currentSlide) {
              currentSlide.style.transform = 'scale(1)';
            }
          }
        },
      };
      
      useEffect(() => {
        const slider = sliderRef.current;

        const handleWheel = (e) => {
            const direction = e.deltaY;

            if (direction > 0 && slider && slider.innerSlider && slider.innerSlider.state.currentSlide === slider.innerSlider.state.slideCount - 1) {
                // Scrolling down, and it's the last slide
                e.preventDefault();
            } else if (direction < 0 && slider && slider.innerSlider && slider.innerSlider.state.currentSlide === 0) {
                // Scrolling up, and it's the first slide
                e.preventDefault();
            }
        };

        window.addEventListener('wheel', handleWheel);

        return () => {
            window.removeEventListener('wheel', handleWheel);
        };
    }, []);
    return (
        <section className={styles.ecosystemSection} >
           <div className={styles.scrollableElem} onWheel={(e) => slickHandleWheelEventDebounced(e, sliderRef.current, false)}>
            <Slider 
                ref={sliderRef}
                // onWheel={(e) => slickHandleWheelEventDebounced(e, sliderRef.current, false)}
                {...settings} 
                >
                 {brands.map((brand, i) => (
                    <ScaleContent 
                    brandsLength={brands.length}
                    index={i + 1}
                    key={brand.id} 
                    brand={brand} 
                    initialScale={0.5} 
                    minScale={0.5} 
                    scaleFactor={900} 
                />
            ))}
            </Slider>
           </div>
           <div className={styles.brandsMobile}>
            {brands.map((brand, i) => (
                      <ScaleContent 
                      brandsLength={brands.length}
                      index={i + 1}
                      key={brand.id} 
                      brand={brand} 
                      initialScale={0.5} 
                      minScale={0.5} 
                      scaleFactor={900} 
                  />
              ))}
           </div>
        </section>
    );
};

export default Ecosystem;