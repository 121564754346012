import React from 'react';
import styles from './style.module.css';
import ReadyToTake from '../../components/ReadyToTake/ReadyToTake';
import img1 from '../../assets/packagingImg1.png';
import img2 from '../../assets/packagingImg2.png';
import img3 from '../../assets/packagingImg3.png';
import img4 from '../../assets/packagingImg4.png';
import img5 from '../../assets/packagingImg5.png';
import img6 from '../../assets/packagingImg6.png';
import img7 from '../../assets/packagingImg7.png';
import img8 from '../../assets/packagingImg8.png';
const Packaging = () => {
    return (
        <section className={styles.packagingSection}>
            <div className={styles.packagingContent}>
                <h2 className={styles.packagingContentTitle}>
                    Innovative packaging: The secret to freshness and quality
                </h2>
                <div className={styles.imagesbox}>
                    <div className={styles.imgBox}>
                        <img src={img1} />
                    </div>
                    <div className={styles.imgBox}>
                        <img src={img2} />
                    </div>
                    <div className={styles.imgBox}>
                        <img src={img3} />
                    </div>
                </div>
                <div className={styles.flexContent}>
                    <div className={styles.contentText}>
                        <p>
                            Welcome to a realm where every step of fruit
                            packaging becomes a crucial endeavor in preserving
                            outstanding quality. At TradeBerry, we present
                            cutting-edge packaging solutions, ensuring not only
                            impeccable freshness but also the preservation of
                            flavor throughout the entire transportation period.
                            We understand that each berry and fruit carries a
                            unique taste and aroma, and our mission is to
                            safeguard this unparalleled natural gift.
                        </p>
                    </div>
                    <div className={styles.imagesbox}>
                        <div className={styles.imgBox}>
                            <img src={img4} />
                        </div>
                        <div
                            className={
                                styles.imgBox + ' ' + styles.imgBoxHidding
                            }
                        >
                            <img src={img5} />
                        </div>
                    </div>
                </div>

                <div className={styles.contentTextSecond}>
                    <div className={styles.infoBox}>
                        <div className={styles.title}>
                            <p>Our Packaging technologies</p>
                        </div>
                        <p>
                            <span className={styles.infoTitle}>
                                Automated distribution systems
                            </span>
                            <br />
                            We employ innovative systems, including robotic
                            devices, for precise and efficient fruit
                            distribution. This not only accelerates the process
                            but also guarantees minimal quality loss.
                        </p>
                        <div
                            className={
                                styles.imgBox + ' ' + styles.mobileImgBox
                            }
                        >
                            <img src={img6} />
                        </div>
                    </div>
                    {/* <br/> */}
                    <div className={styles.infoBox}>
                        <p className={styles.infoTitleBold}>
                            <span className={styles.infoTitle}>
                                Vision technology
                            </span>
                            <br />
                            Our vision systems provide high-precision
                            recognition of the size and quality of each fruit.
                            This approach allows us to pack products with
                            maximum care for their integrity.
                        </p>
                        <div
                            className={
                                styles.imgBox + ' ' + styles.mobileImgBox
                            }
                        >
                            <img src={img7} />
                        </div>
                    </div>
                    <div className={styles.infoBox}>
                        <p>
                            <span className={styles.infoTitle}>
                                Intelligent weight and count management
                            </span>
                            <br />
                            Using IPWC (Intelligent Package Weight & Counts), we
                            create optimal combinations of fruits to achieve the
                            desired total package weight. This ensures efficient
                            resource utilization and high productivity.
                        </p>
                        <p>
                            <span className={styles.infoTitle}>
                                Final quality control
                            </span>
                            <br />
                            The unique iQS system allows for a final quality
                            check before the fruits are placed on the tray,
                            providing you with confidence that each product
                            meets TradeBerry's high standards.
                        </p>
                        <div className={styles.title}>
                            <p> Environmental consciousness</p>
                        </div>
                        <p>
                            <br />
                            We take responsibility for the environment by using
                            environmentally sustainable packaging materials.
                            This is our contribution to creating a sustainable
                            and responsible approach to production and
                            transportation. <br />
                            Modern achievements in biotechnology enable us to
                            use innovative materials based on natural
                            components, extending the shelf life of products and
                            reducing environmental impact.
                            <br />
                            By choosing TradeBerry, you not only acquire fresh
                            fruits but also become part of our mission to blend
                            modern technology with natural beauty.
                        </p>

                        <span className={styles.infoTitle}>
                            Awaken the taste of nature with TradeBerry!
                        </span>
                        <div
                            className={
                                styles.imgBox + ' ' + styles.mobileImgBox
                            }
                        >
                            <img src={img8} />
                        </div>
                    </div>
                </div>
                <div className={styles.imagesbox + ' ' + styles.hideingBox}>
                    <div className={styles.imgBox}>
                        <img src={img6} />
                    </div>
                    <div className={styles.imgBox}>
                        <img src={img7} />
                    </div>
                    <div className={styles.imgBox}>
                        <img src={img8} />
                    </div>
                </div>
            </div>
            <ReadyToTake />
        </section>
    );
};

export default Packaging;
