const Fruit2 = () => {
    return (
        <>
            <path d="M223.111 171.555C222.725 161.256 219.513 139.211 194.75 121.064C177.225 102.464 83.4268 80.0146 74.3571 194.415C73.7142 105.188 143.706 83.2331 190.262 114.271C189.268 110.385 184.956 98.8447 170.273 84.0488C162.606 75.3564 136.514 59.8115 97.7111 76.4531C91.6296 78.8116 76.1719 88.1245 62.9928 106.509C57.9017 112.976 48.6273 133.871 52.2586 165.714C53.1707 173.712 67.8764 204.351 66.9957 215.098C66.792 215.765 66.3218 216.899 67.3067 217.011C72.0977 216.625 82.3834 218.879 97.4144 231.057L97.4548 231.083C114.347 241.846 144.497 261.056 182.786 239.772C194.718 233.139 222.673 212.894 223.111 171.555Z" fill="#191D29" />
            <path d="M209.37 107.237L201.829 112.91L202.93 110.833C199.766 112.847 193.452 116.94 193.508 117.2C212.108 136.932 228.412 148.847 254.248 151.629C257.55 151.758 270.182 152.06 279.905 144.347C280.266 144.06 279.776 144.495 280.131 144.184C280.086 144.078 280.042 143.973 279.997 143.868C263.969 106.074 239.655 103.128 230.354 102.492C226.133 102.452 216.026 103.346 209.37 107.237Z" fill="#191D29" />
            <path d="M200.13 94.2977C199.346 102.65 198.928 106.801 193.478 114.253C191.652 118.05 194.945 119.692 197.64 118.161C199 117.561 200.657 116.427 202.298 115.007C205.454 112.274 207.04 108.297 209.148 104.693C209.513 104.068 209.906 103.5 210.323 103.047C210.919 102.4 211.785 102.127 212.615 101.835C215.102 100.958 216.654 99.6686 216.617 98.4664C216.595 97.7814 215.856 95.9502 213.435 93.0748C211.073 90.2705 207.644 87.7226 204.125 88.7509C202.151 89.3276 200.45 90.8912 200.13 94.2977Z" fill="#191D29" />
            <path data-stroke="true" d="M213.26 168.69C212.874 158.391 209.662 136.346 184.899 118.198C167.374 99.5991 73.5757 77.1494 64.506 191.55C63.8632 102.323 133.855 80.3679 180.411 111.406C179.416 107.52 175.105 95.9795 160.422 81.1836C152.755 72.4911 126.663 56.9463 87.86 73.5879C81.7785 75.9463 66.3208 85.2592 53.1417 103.643C48.0506 110.11 38.7762 131.006 42.4075 162.849C43.368 171.271 58.0253 201.486 57.1446 212.233C56.941 212.9 56.4708 214.034 57.4557 214.146C62.2466 213.759 72.5323 216.014 87.5633 228.192L87.6037 228.217C104.496 238.981 134.646 258.191 172.935 236.907C184.867 230.274 212.822 210.029 213.26 168.69Z" stroke="white" strokeOpacity="0.5" strokeWidth="2.7115" />
            <path data-stroke="true" d="M199.52 104.372L191.978 110.045L193.079 107.968C189.915 109.982 183.601 114.075 183.657 114.334C202.258 134.066 218.561 145.982 244.398 148.764C247.699 148.893 260.332 149.195 270.054 141.482C270.415 141.195 269.925 141.63 270.28 141.319C270.236 141.213 270.191 141.108 270.147 141.003C254.119 103.208 229.805 100.262 220.504 99.6267C216.282 99.5872 206.175 100.481 199.52 104.372Z" stroke="white" strokeOpacity="0.5" strokeWidth="2.7115" />
            <path data-stroke="true" d="M190.28 91.4325C189.495 99.7844 189.077 103.936 183.627 111.388C181.802 115.185 185.095 116.827 187.789 115.296C189.149 114.696 190.806 113.562 192.447 112.142C195.604 109.409 197.189 105.432 199.297 101.827C199.662 101.203 200.055 100.634 200.473 100.181C201.068 99.5347 201.935 99.2617 202.764 98.9694C205.252 98.0926 206.804 96.8034 206.766 95.6012C206.745 94.9162 206.006 93.085 203.584 90.2096C201.223 87.4053 197.793 84.8574 194.274 85.8857C192.3 86.4624 190.6 88.026 190.28 91.4325Z" stroke="white" strokeOpacity="0.5" strokeWidth="2.7115" />
        </>
    );
}

export default Fruit2;