import React from 'react';
import styles from "./style.module.css"
import BlogImg from "../../assets/Boreal-Beauty.png"
import BlogItemBox from './BlogItemBox/BlogItemBox';
import StayInKnowBox from './StayInKnowBox/StayInKnowBox';

const StayInKnow = () => {
    const blogData = [
        {
            id:1,
            title:"Join our community today and stay up-to-date with the latest industry news and trends.",
            date:'November 15, 2023',
            img: BlogImg
        },
        {
            id:2,
            title:"Join our community today and stay up-to-date with the latest industry news and trends.",
            date:'November 15, 2023',
            img: BlogImg
        }
    ]
    return (
        <section className={styles.stayInKnowSection}>
            <div className={styles.stayInKnowContent}>
                <StayInKnowBox />
                {blogData.map(blogItem => (
                    <BlogItemBox key={blogItem.id} blogItem={blogItem} />
                ))}
            </div>
        </section>
    );
};

export default StayInKnow;