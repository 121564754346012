import React, { useEffect, useRef } from 'react';
import styles from './style.module.css';
import Certificates from '../../components/Certificates/Certificates';
import ReadyToTake from '../../components/ReadyToTake/ReadyToTake';
import WhyUs from '../../components/whyUsSection/WhyUs';
import Brands from '../../components/Brands/Brands';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
const AboutUs = () => {
    const firstMaskContentRef = useRef(null);
    const secondMaskContentRef = useRef(null);
    const certificatesRef = useRef(null);
    const readyToTakeRef = useRef(null);
    const whyUsRef = useRef(null);
    const brandsRef = useRef(null);
    const refs = [
        firstMaskContentRef,
        secondMaskContentRef,
        certificatesRef,
        readyToTakeRef,
        whyUsRef,
        brandsRef,
    ];
    // const scrollToRef = (ref) => {
    //     ref.current.scrollIntoView();
    // };

    // useEffect(() => {
    //     refs.forEach((ref, index) => {
    //       if (ref.current) {
    //         const nextRef = refs[index + 1];

    //         // Check if there is a next ref
    //         if (nextRef && nextRef.current) {
    //           const triggerPosition = 'top top';
    //           const endPosition = `+=${nextRef.current.offsetTop}`;

    //           gsap.fromTo(
    //             ref.current,
    //             {
    //               y: 0,
    //             },
    //             {
    //               scrollTrigger: {
    //                 trigger: ref.current,
    //                 start: triggerPosition,
    //                 end: endPosition,
    //                 scrub: true,
    //               },
    //               y: -index * 1000, // Adjust the value based on your needs
    //             }
    //           );
    //         }
    //       }
    //     });
    //   }, [refs]);
    return (
        <section className={styles.aboutUsSection}>
            <div className={styles.aboutContent}>
                <div
                    className={styles.firstMaskContent}
                    ref={firstMaskContentRef}
                    id="firstMaskContent"
                >
                    <div className={styles.title}>
                        <h1>Continuing Family Traditions.</h1>
                    </div>
                    <div className={styles.contentLeft}>
                        {/* <img src={maskImg1} /> */}
                    </div>
                    <div className={styles.contentRight}>
                        <div className={styles.rightTitle}>
                            <h1>Continuing Family Traditions.</h1>
                        </div>
                        <div className={styles.contentText}>
                            <div>
                                <span className={styles.contentTitle}>
                                    History
                                </span>
                                <p>
                                    Established By the Tradeberry family,
                                    TradeBerry is a company that specializes in
                                    cultivating, distributing, and importing
                                    high-quality and unique fruits grown in the
                                    sunny lands of Armenia. With us, you don't
                                    just acquire fruits; you become a part of
                                    our family history, passed down from
                                    generation to generation.
                                    <br />
                                    Over the years, we have tended to our
                                    orchards with love and respect. Our roots
                                    run deep in history, starting with our
                                    founder, who worked on over 1000 hectares of
                                    orchards with plums, pomegranates, and
                                    nectarines. This experience laid the
                                    foundation for creating our unique brand.
                                </p>
                            </div>
                            <div>
                                <span className={styles.contentTitle}>
                                    Our Orchards
                                </span>
                                <p>
                                    Our intensive orchards are situated in one
                                    of the most picturesque regions of Armavir,
                                    Armenia, where favorable climatic conditions
                                    and rich soil create an ideal environment
                                    for cultivating rare and delightful fruit
                                    varieties. Some exceptional fruits grown in
                                    our orchard include Apricots, Flat Peaches,
                                    Peaches, Nectarines, and Plums.
                                    <br />
                                    <em>
                                        Intensive orchard farming is a
                                        contemporary agricultural approach that
                                        focuses on maximizing land efficiency
                                        and achieving high fruit yields.
                                        Orchards with tightly arranged fruit
                                        trees start bearing fruit as early as
                                        the second year, ensuring a more rapid
                                        and effective cultivation process. By
                                        promoting early fruiting, employing
                                        high-productivity varieties, and
                                        utilizing modest tree sizes, intensive
                                        orchard farming ensures a substantial
                                        fruit harvest and optimizes planting
                                        areas, reducing labor and resource
                                        costs.
                                    </em>{' '}
                                    <br />
                                    We take pride in our meticulous selection of
                                    varieties, high cultivation standards, and
                                    careful nurturing each tree. Fruits grown in
                                    our orchards meet the high demand of our
                                    customers for healthy, natural food
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.certificatesRef} ref={certificatesRef}>
                    <Certificates />
                </div>
                <div
                    ref={secondMaskContentRef}
                    className={styles.secondMaskContent}
                    id="secondMaskContent"
                >
                    <div className={styles.title}>
                        <h1>Revolutionizing оrchard еxcellence</h1>
                    </div>
                    <div className={styles.contentLeft}>
                        {/* <img src={maskImg1} /> */}
                    </div>
                    <div className={styles.contentRight}>
                        <div className={styles.rightTitle}>
                            <h1>Revolutionizing оrchard еxcellence</h1>
                        </div>
                        <div className={styles.contentText}>
                            <div>
                                <span className={styles.contentTitle}>
                                    Fruit preservation guarantee
                                </span>
                                <p>
                                    We take pride in implementing advanced
                                    Italian hail protection systems to safeguard
                                    our orchards. This technology offers
                                    reliable defense against adverse weather
                                    conditions, minimizing the risk of crop
                                    damage from hail. The benefits include
                                    efficient monitoring and prompt response to
                                    potential threats, ensuring the safety and
                                    quality of our fruits. This innovative
                                    system underscores our commitment to high
                                    standards and the care we invest in the
                                    produce we provide.
                                </p>
                            </div>
                            <div>
                                <span className={styles.contentTitle}>
                                    Storage and Transportation
                                </span>
                                <p>
                                    Understanding the importance of quality
                                    transportation, we employ unique packaging
                                    techniques. TradeBerry offers not just
                                    fruits but the art of preserving taste and
                                    aroma throughout the journey from our
                                    orchards to your shelves. Our storage
                                    conditions are equally impressive –
                                    high-tech refrigerators ensure the
                                    preservation of freshness and nutrients,
                                    undoubtedly one of our key advantages.
                                </p>
                            </div>
                            <div>
                                <span className={styles.contentTitle}>
                                    Partnership
                                </span>
                                <p>
                                    At TradeBerry, we place your business at the
                                    forefront. Our reliable partnership extends
                                    far beyond fruit deliveries – we offer
                                    advantageous opportunities for exporting our
                                    products not only to European markets but
                                    also to many other countries. Fruits
                                    cultivated under the Armenian sun can now
                                    become an integral part of your business.{' '}
                                    <br />
                                    We invite companies and distributors to
                                    become our partners in distributing unique
                                    Armenian fruits. Contact us to learn more
                                    about collaboration opportunities and
                                    additional terms.
                                    <br />
                                    Contact us:
                                    <span className={styles.contentTitle}>
                                        Supercharge Your Orchard Business!
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.ReadyToTake} ref={readyToTakeRef}>
                    <ReadyToTake />
                </div>
                <div className={styles.whyUs} ref={whyUsRef}>
                    <WhyUs />
                </div>
                <div className={styles.brands} ref={brandsRef}>
                    <Brands />
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
