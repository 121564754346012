import React from 'react';

const GroupSvg = () => {
    return (
        <svg width="160" height="30" viewBox="0 0 173 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group">
            <path id="Vector" d="M80.1959 20.1289C80.0228 19.9978 79.5126 19.6449 79.1983 19.4987C78.8795 19.3475 78.8157 19.3626 78.6381 19.6953C78.5242 19.897 78.2236 20.4666 78.0824 20.7287C77.513 21.6361 76.807 22.8107 75.1717 23.1334C73.8508 23.3602 72.6392 22.8611 72.0015 22.3621C70.5666 21.2228 70.061 19.3475 70.061 19.3475C70.061 19.3475 71.5687 19.5239 72.7986 19.4483C73.5638 19.3979 75.5543 19.2668 77.3126 17.9662C78.6472 16.9832 79.754 15.3045 79.6265 13.52C79.4716 11.3876 78.096 9.99123 76.5291 9.28044C74.1286 8.22685 71.7737 8.84187 71.7737 8.84187C69.0863 9.4468 67.0729 11.307 66.1027 13.8426C65.6882 14.9265 65.4696 16.1565 65.4104 17.447C65.2418 21.1774 67.4237 26.1025 73.081 26.1025C77.6041 26.1025 79.4762 22.8006 80.0365 21.4698C80.082 21.3488 80.2414 20.9758 80.3143 20.7489C80.4191 20.4112 80.4737 20.3456 80.1959 20.1289ZM69.8242 14.2005C70.0884 12.784 71.0996 10.8885 72.8942 10.7877C74.9759 10.6718 75.609 12.6479 75.3448 14.2509C75.0897 15.8238 73.9965 17.0185 72.3977 17.4369C71.2909 17.7243 69.7741 17.7243 69.7741 17.7243C69.7741 17.7243 69.4552 16.1615 69.8242 14.2005Z" fill="white"/>
            <path id="Vector_2" d="M99.1174 13.0056C98.1244 12.1335 96.8763 11.6092 95.4005 11.5739C94.0112 11.5739 93.1003 11.7655 92.2029 12.1184C91.547 12.3755 91.0778 12.5166 91.0778 12.5166C90.6406 12.6477 90.7772 11.9621 91.5561 11.5084C92.5081 10.9539 93.3417 10.6766 94.7173 10.1624C96.2204 9.59781 97.5049 8.38795 97.5049 6.3816C97.5049 3.81065 95.6966 2.45964 94.5168 1.78413C93.1321 1.00781 91.2327 0.503693 88.149 0.503693H83.02C82.5099 0.503693 82.0908 0.962436 82.0908 1.53208V24.7513C82.0908 25.3159 82.5053 25.7797 83.02 25.7797H89.7751C91.055 25.7797 92.3578 25.6335 92.9773 25.5125C95.8788 24.953 101.026 22.9012 101.026 17.5627C101.026 15.6068 100.293 14.039 99.1174 13.0056ZM94.2709 22.4576C92.622 23.8843 90.7453 23.9901 89.2604 23.9901H86.9738V2.2832L88.8596 2.26807C91.3648 2.26807 93.0957 4.06774 93.0957 6.42192C93.0957 9.08866 91.7975 10.2632 90.5358 11.0648C89.4471 11.7554 88.9324 12.3502 88.9734 13.1165C89.0281 13.6055 89.4244 13.7718 89.93 13.7517C90.5084 13.7315 90.6998 13.6559 91.5561 13.5954C93.4829 13.4643 95.6829 14.1348 96.2386 17.3258C96.5757 19.6649 95.4233 21.4645 94.2709 22.4576Z" fill="white"/>
            <path id="Vector_3" d="M63.4059 1.83475C63.0187 0.922312 62.085 0.398031 60.8323 0.519017C59.7847 0.619838 59.0422 1.12899 58.737 1.4365C58.6505 1.5222 58.6459 1.66839 58.7188 1.76921C58.8008 1.88516 58.9147 2.08679 59.0422 2.42455C59.2199 2.90849 59.2973 3.69491 59.2973 4.49644L59.2928 10.7928C59.2472 10.7373 57.8261 8.35286 54.7104 8.69566C52.6288 8.92251 48.543 11.1708 48.543 17.3613C48.543 23.2342 51.4718 25.921 54.3324 26.0672C57.5664 26.2336 59.3201 23.5769 59.3201 23.5769L59.3429 23.5719C59.4613 24.807 60.4042 25.7648 61.5475 25.7648H63.4424C63.5653 25.7648 63.6655 25.6539 63.6655 25.5178V3.71506H63.661C63.6428 2.85304 63.5927 2.27836 63.4059 1.83475ZM59.2882 21.6815C59.2882 21.6815 58.0629 23.8643 55.7262 22.9871C53.7311 22.2411 53.0433 19.3071 53.0433 17.326C53.0433 14.6139 54.2139 11.8564 56.5279 11.4884C58.2998 11.2061 59.2928 12.421 59.2928 12.421L59.2882 21.6815Z" fill="white"/>
            <path id="Vector_4" d="M16.0206 3.38701C16.3121 3.41222 16.5763 3.71468 17.1001 4.02722C17.9519 4.53133 19.2865 5.05561 20.9582 5.05561C25.791 5.05561 26.6838 1.07819 26.702 1.00257C26.7704 0.71523 26.6064 0.50351 26.3331 0.50351H14.5584C13.6884 0.50351 12.9778 1.28488 12.9778 2.25277V3.24586H1.29882C1.12117 3.24586 0.966302 3.3366 0.884312 3.49792C0.834207 3.5937 0.82054 3.73988 0.83876 3.88607C0.916195 4.47588 1.74521 7.44005 5.90847 7.44005C8.28618 7.44005 10.131 5.90251 10.2676 5.79665C10.6457 5.49923 10.8916 5.52444 11.0693 5.6303C11.2105 5.716 11.3289 5.85714 11.3289 6.37637V25.3157C11.3289 25.5728 11.5202 25.7846 11.7525 25.7846H15.7974C16.0297 25.7846 16.221 25.5728 16.221 25.3157C16.221 25.3157 16.221 9.78416 16.221 8.09035C16.221 6.40159 16.2984 5.44882 15.9477 4.60696C15.7928 4.23392 15.688 3.97683 15.688 3.70965C15.6835 3.45255 15.8657 3.35676 16.0206 3.38701Z" fill="white"/>
            <path id="Vector_5" d="M159.749 10.203C159.361 9.29052 158.428 8.76625 157.175 8.88724C156.127 8.98806 155.389 9.49721 155.08 9.80472C154.993 9.89042 154.989 10.0366 155.061 10.1374C155.143 10.2534 155.257 10.455 155.385 10.7928C155.54 11.2112 155.622 11.8615 155.64 12.5521C155.64 12.5521 155.64 20.6431 155.64 21.1623C155.64 23.1586 154.738 24.0609 153.271 24.0609C151.805 24.0609 151.048 22.7049 151.048 21.0816C151.048 21.0715 151.048 12.4362 151.048 12.4362C151.044 11.3624 151.003 10.697 150.789 10.1979C150.402 9.28549 149.468 8.76121 148.215 8.88219C147.168 8.98301 146.43 9.49217 146.12 9.79967C146.033 9.88537 146.029 10.0316 146.102 10.1324C146.184 10.2483 146.298 10.45 146.425 10.7877C146.603 11.2666 146.68 12.0581 146.68 12.8546C146.68 12.8848 146.68 20.5624 146.68 20.5775C146.68 23.3148 148.566 25.4472 151.54 25.911C154.515 26.3748 155.635 24.5196 155.635 24.5196C155.635 24.5196 155.635 25.3111 155.635 27.5796C155.635 29.8481 154.533 30.826 153.294 30.826C151.386 30.826 150.661 29.2028 150.634 29.1524C150.297 28.5072 150.183 27.872 149.969 27.4536C149.386 26.3294 148.179 25.9261 147.241 26.0572C147.172 26.0622 147.108 26.0773 147.045 26.0925C146.644 26.1832 146.348 26.3596 146.348 26.9595C146.348 27.2318 146.489 27.8921 146.489 27.9073C146.881 29.3994 147.746 30.4026 148.712 31.1386C149.864 32.0157 151.458 32.4543 153.121 32.4543C155.23 32.4543 156.924 31.5066 157.763 30.7756C159.334 29.3994 159.999 27.509 159.999 24.7314C159.999 22.9872 159.999 12.5118 159.999 12.5118C160.004 11.4027 159.967 10.7171 159.749 10.203Z" fill="white"/>
            <path id="Vector_6" d="M29.4948 8.69568C26.9531 8.69568 25.286 10.576 24.7986 11.2061C24.7621 10.7978 24.6984 10.4802 24.5845 10.208C24.1973 9.29557 23.2635 8.7713 22.0109 8.89229C20.9633 8.99311 20.2208 9.50225 19.9156 9.80975C19.8291 9.89545 19.8245 10.0366 19.8928 10.1374C19.9748 10.2534 20.0933 10.455 20.2163 10.7978C20.3939 11.2818 20.4713 12.0682 20.4713 12.8697L20.4668 20.1843L20.4713 20.1793V25.5279C20.4713 25.664 20.5715 25.7749 20.6945 25.7749H24.6209C24.7439 25.7749 24.8441 25.664 24.8441 25.5279V14.3316C24.8441 14.3316 24.8715 13.7368 25.3087 13.2024C25.6959 12.7285 26.2972 12.2597 27.1899 12.0833C28.7751 11.8413 29.4766 12.779 29.9184 13.4293C30.1279 13.7368 30.2919 14.0644 30.424 14.0644C30.7337 14.1552 32.0091 13.5301 32.41 12.0329C32.7015 10.9541 32.3918 8.69568 29.4948 8.69568Z" fill="white"/>
            <path id="Vector_7" d="M128.141 8.69568C125.6 8.69568 123.932 10.576 123.445 11.2061C123.409 10.7978 123.345 10.4802 123.231 10.208C122.844 9.29557 121.91 8.7713 120.657 8.89229C119.61 8.99311 118.867 9.50225 118.562 9.80975C118.476 9.89545 118.471 10.0366 118.539 10.1374C118.621 10.2534 118.74 10.455 118.863 10.7978C119.04 11.2818 119.118 12.0682 119.118 12.8697L119.113 20.1843L119.118 20.1793V25.5279C119.118 25.664 119.218 25.7749 119.341 25.7749H123.267C123.39 25.7749 123.491 25.664 123.491 25.5279V14.3316C123.491 14.3316 123.518 13.7368 123.955 13.2024C124.342 12.7285 124.944 12.2597 125.836 12.0833C127.422 11.8413 128.123 12.779 128.565 13.4293C128.774 13.7368 128.938 14.0644 129.07 14.0644C129.38 14.1552 130.656 13.5301 131.056 12.0329C131.348 10.9541 131.038 8.69568 128.141 8.69568Z" fill="white"/>
            <path id="Vector_8" d="M141.97 8.69568C139.429 8.69568 137.762 10.576 137.274 11.2061C137.238 10.7978 137.174 10.4802 137.06 10.208C136.673 9.29557 135.739 8.7713 134.487 8.89229C133.439 8.99311 132.696 9.50225 132.391 9.80975C132.305 9.89545 132.3 10.0366 132.368 10.1374C132.45 10.2534 132.569 10.455 132.692 10.7978C132.869 11.2818 132.947 12.0682 132.947 12.8697L132.942 20.1843L132.947 20.1793V25.5279C132.947 25.664 133.047 25.7749 133.17 25.7749H137.097C137.22 25.7749 137.32 25.664 137.32 25.5279V14.3316C137.32 14.3316 137.347 13.7368 137.784 13.2024C138.171 12.7285 138.773 12.2597 139.666 12.0833C141.251 11.8413 141.952 12.779 142.394 13.4293C142.604 13.7368 142.767 14.0644 142.9 14.0644C143.209 14.1552 144.485 13.5301 144.886 12.0329C145.177 10.9541 144.867 8.69568 141.97 8.69568Z" fill="white"/>
            <path id="Vector_9" d="M46.8028 15.9498C46.8028 15.7885 46.8028 15.3802 46.8028 14.4627C46.8028 11.9774 45.1311 8.67551 40.2482 8.67551C35.3652 8.67551 33.7801 11.4279 33.73 12.4967C33.7026 13.1671 34.4542 13.8527 35.0965 14.0594C35.3925 14.1501 35.9619 14.2509 36.0758 14.0796C36.1395 13.9838 36.1714 13.8729 36.2352 13.646C36.4903 12.542 37.3694 10.7978 39.5922 10.7978C40.2299 10.7978 40.9633 10.949 41.5554 11.5237C42.2569 12.2043 42.4346 13.2276 42.4346 14.0392V16.0103C42.4346 16.0103 42.1977 15.9548 42.1931 15.9548C41.8333 15.8742 41.1045 15.733 40.1935 15.6826C40.0705 15.6776 39.943 15.6725 39.8154 15.6675C39.6742 15.6625 39.5239 15.6574 39.3782 15.6625C38.9363 15.6625 38.4854 15.6927 38.0435 15.7633C37.998 15.7683 37.957 15.7784 37.9114 15.7834C36.5267 16.0002 35.0873 16.5598 34.0215 17.7797C33.9896 17.81 33.9623 17.8452 33.9349 17.8805C33.6525 18.2032 33.402 18.5762 33.2061 19.0047C33.2016 19.0097 33.197 19.0148 33.197 19.0249C32.86 19.7256 32.7233 20.4414 32.7188 21.127C32.7188 23.7131 34.4087 25.2304 36.0211 25.8102C37.5334 26.284 39.0456 26.1076 39.9794 25.7043C41.0863 25.2254 41.8287 24.4037 42.1567 24.0055C42.1886 23.9702 42.2159 23.9349 42.2432 23.8996C42.348 23.7736 42.4664 23.6072 42.4755 23.5921C42.4755 23.5921 42.4983 23.8089 42.5575 24.0206C42.8172 25.0389 43.6416 25.785 44.6483 25.785H46.575C46.698 25.785 46.7982 25.6741 46.7982 25.5379V16.0204C46.8028 15.9649 46.8028 15.9498 46.8028 15.9498ZM42.4346 21.6815C42.4346 21.6815 41.478 23.4913 39.0775 23.1535C36.6269 22.8057 36.6679 19.9726 37.7247 18.6922C38.7815 17.4117 40.2527 17.079 42.4346 17.6234V21.6815Z" fill="white"/>
            <path id="Vector_10" d="M117.556 20.1289C117.383 19.9978 116.873 19.6449 116.559 19.4987C116.24 19.3475 116.176 19.3626 115.998 19.6953C115.885 19.897 115.584 20.4666 115.443 20.7287C114.873 21.6361 114.167 22.8107 112.532 23.1334C111.211 23.3602 110 22.8611 109.362 22.3621C107.927 21.2228 107.421 19.3475 107.421 19.3475C107.421 19.3475 108.929 19.5239 110.159 19.4483C110.924 19.3979 112.915 19.2668 114.673 17.9662C116.008 16.9832 117.114 15.3045 116.987 13.52C116.832 11.3876 115.456 9.99123 113.889 9.28044C111.489 8.22685 109.134 8.84187 109.134 8.84187C106.447 9.4468 104.433 11.307 103.463 13.8426C103.049 14.9265 102.83 16.1565 102.771 17.447C102.602 21.1774 104.784 26.1025 110.441 26.1025C114.964 26.1025 116.837 22.8006 117.397 21.4698C117.442 21.3488 117.602 20.9758 117.675 20.7489C117.779 20.4112 117.834 20.3456 117.556 20.1289ZM107.185 14.2005C107.449 12.784 108.46 10.8885 110.255 10.7877C112.336 10.6718 112.969 12.6479 112.705 14.2509C112.45 15.8238 111.357 17.0185 109.758 17.4369C108.651 17.7243 107.134 17.7243 107.134 17.7243C107.134 17.7243 106.816 16.1615 107.185 14.2005Z" fill="white"/>
        </g>
        </svg>
    );
};

export default GroupSvg;