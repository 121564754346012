import React, { useEffect, useState } from 'react';
import styles from "./style.module.css"
import kundig from "../../assets/kundig.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

const Brands = () => {
    const brands = [
        {
            id:1,
            img:kundig
        },
        {
            id:2,
            img:kundig
        },
        {
            id:3,
            img:kundig
        },
        {
            id:4,
            img:kundig
        },
        {
            id:5,
            img:kundig
        },
        {
            id:6,
            img:kundig
        },
    ];
    const settings = {
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // centerMode: true,
        // initialSlide: 1, 
        // centerPadding: "30px",
        // centerPadding: "60px",
        variableWidth: true,
        responsive: [
          {
            breakpoint: 1800,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
            {
              breakpoint: 1650,
              settings: {
                slidesToShow: 3.5,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 1440,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },
            {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 2.5,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2.5,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 430,
                settings: {
                  slidesToShow: 1.5,
                  slidesToScroll: 1,
                },
              },
            // Add more breakpoints as needed
          ],
      }; 
      const mediaMatch = window.matchMedia('(max-width: 768px)');
      const [matches, setMatches] = useState(mediaMatch.matches);
    
      useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
      });
    return (
        <section className='brandsSection'>
            <div className={styles.brandsSectionContent}>
                <Slider 
                      {...settings} 
                      // ref={(c) => (slider.current = c)}
                      >
                      {
                          brands.map(brand=> (
                              <div key={brand.id} className={styles.slideItem} style={{width: matches && 104, marginRight:20}}>
                                  <img src={brand.img} />
                              </div>
                          ))
                      }
                </Slider>
            </div>
        </section>
    );
};

export default Brands;