import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
const Wrapper = () => {
    
    return (
        <>
            <Header />
            <Outlet/>
            <Footer />
        </>
    );
};

export default Wrapper;