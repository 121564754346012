import React from 'react';
import styles from "./style.module.css";

const Brands = () => {
    return (
        <section className={styles.brandsSection}>
            Brands
        </section>
    );
};

export default Brands;