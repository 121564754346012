import { useState } from 'react';

import HandPointIcon from './components/HandPoint';
import SvgComponent from './components/SvgComponent/SvgComponent';
import CircleSlider from './components/CircleSlider/CircleSlider';

import Fruit1 from './components/Icons/Fruit1';
import Fruit2 from './components/Icons/Fruit2';

import styles from './style.module.css';

const InteractiveMap = () => {
    const [selectedType, setSelectedType] = useState(null);
    const [selectedGuid, setSelectedGuid] = useState(null);
    const [selectedSingleType, setSelectedSingleType] = useState(null);

    return (
        <section className={styles.landSchemeRoot}>
            <div className={styles.landSchemeMap}>
                <div className={styles.contentBox}>
                    <p className={styles.contentTitle}>
                        TRADEBERRY INERACTIVE MAP
                    </p>
                    <div className={styles.contentInfo}>
                        <HandPointIcon />
                        <p className={styles.contentSubtitle}>
                            Click on the square to view additional information
                        </p>
                    </div>
                </div>
                <SvgComponent
                    selected={selectedGuid}
                    selectedType={selectedType}
                    onSelect={(item, type) => {
                        setSelectedGuid(item);
                        setSelectedType(null);
                        setSelectedSingleType(type);
                    }}
                />
            </div>
            <CircleSlider
                data={FRUITS}
                selectedType={selectedType || selectedSingleType}
                onSelect={(index) => {
                    setSelectedType(String(index));
                    setSelectedGuid(null);
                }}
            />
        </section>
    );
};

export default InteractiveMap;

const FRUITS = [
    {
        name: 'Peach',
        variety: 'Elegant Lady 1',
        hectare: 4.9,
        three: 1040,
        image: <Fruit1 />,
    },
    {
        name: 'Peach',
        variety: 'Elegant Lady 2',
        hectare: 4.9,
        three: 1040,
        image: <Fruit2 />,
    },
    {
        name: 'Peach',
        variety: 'Elegant Lady 3',
        hectare: 4.9,
        three: 1040,
        image: <Fruit1 />,
    },
    {
        name: 'Peach',
        variety: 'Elegant Lady 4',
        hectare: 4.9,
        three: 1040,
        image: <Fruit1 />,
    },
    {
        name: 'Peach',
        variety: 'Elegant Lady 5',
        hectare: 4.9,
        three: 1040,
        image: <Fruit2 />,
    },
    {
        name: 'Peach',
        variety: 'Elegant Lady 6',
        hectare: 4.9,
        three: 1040,
        image: <Fruit1 />,
    },
    {
        name: 'Peach',
        variety: 'Elegant Lady 7',
        hectare: 4.9,
        three: 1040,
        image: <Fruit1 />,
    },
    {
        name: 'Peach',
        variety: 'Elegant Lady 8',
        hectare: 4.9,
        three: 1040,
        image: <Fruit1 />,
    },
    {
        name: 'Peach',
        variety: 'Elegant Lady 9',
        hectare: 4.9,
        three: 1040,
        image: <Fruit1 />,
    },
    {
        name: 'Peach',
        variety: 'Elegant Lady 10',
        hectare: 4.9,
        three: 1040,
        image: <Fruit1 />,
    },
    {
        name: 'Peach',
        variety: 'Elegant Lady 11',
        hectare: 4.9,
        three: 1040,
        image: <Fruit1 />,
    },
    {
        name: 'Peach',
        variety: 'Elegant Lady 12',
        hectare: 4.9,
        three: 1040,
        image: <Fruit1 />,
    },
];
