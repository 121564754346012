import React, { useEffect, useState } from 'react';
import styles from "./style.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import certificate1 from "../../assets/certificate1.png";
import certificate2 from "../../assets/certificate2.png";
import certificate3 from "../../assets/certificate3.png";
import certificate4 from "../../assets/certificate4.png";
import certificate5 from "../../assets/certificate5.png";
const Certificates = () => {
    const sliderData = [
        {
            id:1,
            img:certificate1
        },
        {
            id:2,
            img:certificate2
        },
        {
            id:3,
            img:certificate3
        },
        {
            id:4,
            img:certificate4
        },
        {
            id:5,
            img:certificate5
        },
    ]
    const settings = {
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      margin: 10,
      padding: "2%",
      variableWidth: true,
      centerPadding: '50px',
      responsive: [
        {
          breakpoint: 1650,
          settings: {
            slidesToShow: 3.5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2.5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 1.5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 430,
          settings: {
            slidesToShow: 1.5,
            slidesToScroll: 1,
          },
        },
        // Add more breakpoints as needed
      ],
    };
    const mediaMatch = window.matchMedia('(min-width: 768px)');
    const [matches, setMatches] = useState(mediaMatch.matches);
  
    useEffect(() => {
      const handler = e => setMatches(e.matches);
      mediaMatch.addListener(handler);
      return () => mediaMatch.removeListener(handler);
    });
    return (
        <section className={styles.certificatesSection}>
            <div className={styles.certificatesContent}>
                <div className={styles.contentLeft}>
                    <p className={styles.conentTitle}>TRADEBERRY CERTIFICATES</p>
                    <h1>Our Commitment to Quality Certifications</h1>
                </div>
                <div className={styles.contentRight}>
                    <Slider 
                        {...settings} 
                        // ref={(c) => (slider.current = c)}
                        >
                        {
                            sliderData.map(cercificate=> (
                                <div key={cercificate.id} className={styles.slideItem} style={{width: matches ? 235 : 171,margin:10}}>
                                    <img src={cercificate.img} />
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default Certificates;