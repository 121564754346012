import React from 'react';
import { Routes, Route } from 'react-router';
import Wrapper from '../Wrapper/Wrapper';
import Home from '../../pages/Home/Home';
import AboutUs from "../../pages/AboutUs/AboutUs";
import Blog from "../../pages/Blog/Blog";
import Brands from "../../pages/Brands/Brands";
import Packaging from "../../pages/Packaging/Packaging";
import Contacts from "../../pages/Contacts/Contacts";
import Refrigerators from "../../pages/Refrigerators/Refrigerators";
import Ecosystem from '../../pages/Ecosystem/Ecosystem';
import Products from '../../pages/Products/Products';
import BlogSingle from '../../pages/BlogSingle/BlogSingle';
const AppRouter = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Wrapper />}>
                    <Route index element={<Home />} />
                    <Route path='about-us' element={<AboutUs />} />
                    <Route path='packaging' element={<Packaging/>} />
                    <Route path='refrigerators' element={<Refrigerators/>} />
                    <Route path='brands' element={<Brands/>} />
                    <Route path='blog' element={<Blog />} />
                    <Route path="blogItem/:slug" element={<BlogSingle/>} />
                    <Route path='contact-us' element={<Contacts/>} />
                    <Route path='ecosystem' element={<Ecosystem/>} />
                    <Route path='products' element={<Products/>} />
                </Route>
            </Routes>
        </div>
    );
};

export default AppRouter;