import React, { useEffect, useState } from 'react';

import styles from './style.module.css';

const CircleSlider = ({ data = [], selectedType, onSelect }) => {
    const numberOfSquares = data.length;
    const center = { x: 50, y: 50 }; // Adjust the center coordinates as needed

    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const updateContainerSize = () => {
            const container = document.getElementById('circle-container');
            if (container) {
                setContainerSize({
                    width: container.clientWidth,
                    height: container.clientHeight,
                });
            }
        };

        window.addEventListener('resize', updateContainerSize);
        updateContainerSize(); // Initial update

        return () => {
            window.removeEventListener('resize', updateContainerSize);
        };
    }, []);

    const params = containerSize.width >= 768 ? {
        radius: 30,
        rotateDeg: 230,
        squareSize: 0.15,
    } : {
        radius: 10,
        rotateDeg: 150,
        squareSize: 0.25,
    };

    const getSquareStyle = (index) => {
        const radius = Math.min(containerSize.width, containerSize.height) / params.radius;

        const angle = (2 * Math.PI * index) / numberOfSquares;
        const x = center.x + radius * Math.cos(angle);
        const y = center.y + radius * Math.sin(angle);

        const squareSize = Math.min(containerSize.width, containerSize.height) * params.squareSize;

        const rotateDeg = (360 / numberOfSquares) * (index % numberOfSquares) + 360 / numberOfSquares;

        return {
            cursor: 'pointer',
            position: 'absolute',
            width: `${squareSize}px`,
            height: `${squareSize}px`,
            top: `calc(${y}% - ${squareSize / 2}px)`,
            left: `calc(${x}% - ${squareSize / 2}px)`,
            transform: `rotate(${rotateDeg + 160}deg)`,
        };
    };

    const rotateDeg = (360 / numberOfSquares) * (selectedType % numberOfSquares) + 360 / numberOfSquares;

    const selectedItem = data?.find((_, index) => index === selectedType - 1);

    return (
        <div className={styles.landSchemeCircle}>
            <div
                id="circle-container"
                className={styles.landSchemeCircleContainer}
                style={{ transform: `rotate(${params.rotateDeg - rotateDeg}deg)` }}
            >
                {data.map((item, index) => (
                    <div key={index} style={getSquareStyle(index)} onClick={() => onSelect(index + 1)}>
                        <svg
                            width='100%'
                            height='100%'
                            viewBox="0 0 280 280"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            data-land-type={index + 1}
                            data-item-selected={+selectedType === index + 1}
                        >
                            {item.image}
                        </svg>
                    </div>
                ))}
            </div>
            {selectedItem &&
                <div className={styles.infoBox}>
                    <p className={styles.infoBoxName}>
                        {selectedItem.name}
                    </p>
                    <p className={styles.infoBoxVariety}>
                        {selectedItem.variety}
                    </p>
                    <p className={styles.infoBoxOptions}>
                        {selectedItem.hectare} Hectare <br />
                        {selectedItem.three} Three
                    </p>
                </div>
            }
        </div>
    );
};

export default CircleSlider;
