import React, { useRef, useState } from 'react';
import styles from './style.module.css';
import MySlider from './components/Myslider/MySlider';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import SlideItem from './components/SlideItem/SlideItem';
import img1 from '../../assets/slideImg1.png';
import img2 from '../../assets/slideImg2.png';
import img3 from '../../assets/slideImg3.png';
import eclipse from '../../assets/Ellipse 426.png';
const WhyUs = () => {
    const slider = useRef();
    const next = () => {
        slider.current.slickNext();
    };
    const previous = () => {
        slider.current.slickPrev();
    };
    const slidtemData = [
        {
            id: 1,
            img: img1,
            title: 'Over 1,000,000 trees',
            conentTitle: 'Our contribution to the Ecosystem.',
            text: 'Thanks to our efforts, more than 500,000 trees already grace our orchards, with an additional 500,000 in the planting stage, shaping our green legacy for future generations.',
        },
        {
            id: 2,
            img: img2,
            title: 'More than 100 units of high-quality agricultural machinery ',
            conentTitle: 'Efficient management of modern resources.',
            text: 'Our state-of-the-art fleet of high-class agricultural machinery enables us to efficiently oversee all aspects of fruit cultivation, ensuring a high level of productivity.',
        },
        {
            id: 3,
            img: img3,
            title: '89,250+ Hours of work',
            conentTitle: 'Dedication and diligence to the craft.',
            text: 'Our team has dedicated over 89,250 hours to the cultivation of our orchards, reflecting our commitment to high quality and care in every aspect of production.',
        },
        {
            id: 4,
            img: img1,
            title: '1 Family',
            conentTitle: 'A legacy passed down through generations.',
            text: 'More than just a company, we are a family business, the sole proprietors of the orchards, imparting a distinctive character and a caring approach to our produce.',
        },
    ];
    const settings = {
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // centerPadding: "60px",
        variableWidth: true,
        responsive: [
            {
                breakpoint: 430,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            // Add more breakpoints as needed
        ],
        beforeChange: (current, next) => {
            if (
                next > current &&
                slider.current &&
                slider.current.innerSlider
            ) {
                const currentSlide =
                    slider.current.innerSlider.list.querySelector(
                        '.slick-current'
                    );
                if (currentSlide) {
                    currentSlide.style.transform = 'scale(0.7)';
                }
            }
        },
        afterChange: () => {
            if (slider.current && slider.current.innerSlider) {
                const currentSlide =
                    slider.current.innerSlider.list.querySelector(
                        '.slick-current'
                    );
                if (currentSlide) {
                    currentSlide.style.transform = 'scale(1)';
                }
            }
        },
    };
    return (
        <section className={styles.whyUsSecion}>
            <div className={styles.whyUsContent}>
                <div className={styles.whyUsContentLeft}>
                    <p className={styles.whyUsContentLeftTitle}>
                        WHY TRADEBERRY?
                    </p>
                    <div className={styles.whyUsContentLeftConent}>
                        <p>
                            Experience pure delight with our premium, natural
                            harvests
                        </p>
                        <br></br>
                        <p>
                            Are you longing for fruits cultivated in pristine,
                            sun-kissed landscapes?
                        </p>
                        <br></br>
                        <p className={styles.whyUsContentLeftConentBold}>
                            Explore our exquisite range of premium fruits,
                            nurtured in the bountiful embrace of Armenia's sunny
                            climate. Immerse yourself in the goodness of nature,
                            where each fruit tells a story of care and quality.
                        </p>
                    </div>
                </div>
                <div className={styles.whyUsContentRight}>
                    <div className={styles.whyUsContentRightTop}>
                        <div className={styles.contentRightTitle}>
                            <h1 className={styles.whyUsContentRightTitle}>
                                At TradeBerry, we are dedicated to producing
                                only the finest fruits for individuals who
                                appreciate excellence
                            </h1>
                            <h1 className={styles.whyUsContentRightTitleMobile}>
                                At TradeBerry, we are dedicated to producing
                                only the finest fruits for individuals who
                                appreciate excellence
                            </h1>
                        </div>

                        <div className={styles.sliderBtns}>
                            <span
                                className={
                                    styles.btnLeft + ' ' + styles.arrowspan
                                }
                                onClick={previous}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="13"
                                    viewBox="0 0 16 13"
                                    fill="none"
                                >
                                    <path
                                        d="M15.5303 7.03033C15.8232 6.73744 15.8232 6.26256 15.5303 5.96967L10.7574 1.1967C10.4645 0.903807 9.98959 0.903807 9.6967 1.1967C9.40381 1.48959 9.40381 1.96447 9.6967 2.25736L13.9393 6.5L9.6967 10.7426C9.40381 11.0355 9.40381 11.5104 9.6967 11.8033C9.98959 12.0962 10.4645 12.0962 10.7574 11.8033L15.5303 7.03033ZM-6.55671e-08 7.25L15 7.25L15 5.75L6.55671e-08 5.75L-6.55671e-08 7.25Z"
                                        fill="white"
                                    />
                                </svg>
                            </span>
                            <span
                                className={
                                    styles.btnRight + ' ' + styles.arrowspan
                                }
                                onClick={next}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="13"
                                    viewBox="0 0 16 13"
                                    fill="none"
                                >
                                    <path
                                        d="M15.5303 7.03033C15.8232 6.73744 15.8232 6.26256 15.5303 5.96967L10.7574 1.1967C10.4645 0.903807 9.98959 0.903807 9.6967 1.1967C9.40381 1.48959 9.40381 1.96447 9.6967 2.25736L13.9393 6.5L9.6967 10.7426C9.40381 11.0355 9.40381 11.5104 9.6967 11.8033C9.98959 12.0962 10.4645 12.0962 10.7574 11.8033L15.5303 7.03033ZM-6.55671e-08 7.25L15 7.25L15 5.75L6.55671e-08 5.75L-6.55671e-08 7.25Z"
                                        fill="white"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>
                    {/* <MySlider /> */}
                    <div className={styles.slicSliderContent}>
                        <Slider {...settings} ref={(c) => (slider.current = c)}>
                            {slidtemData.map((item, index) => (
                                <SlideItem key={item.id} itemData={item} />
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyUs;
