import React from 'react';
import WhyUs from '../../components/whyUsSection/WhyUs';
import InteractiveMap from '../../components/InteractiveMap/InteractiveMap';
import StayInKnow from '../../components/StayInKnow/StayInKnow';
import Community from '../../components/Community/Community';
import ReadyToTake from '../../components/ReadyToTake/ReadyToTake';
import Certificates from '../../components/Certificates/Certificates';
import Brands from '../../components/Brands/Brands';

const Home = () => {
    return (
        <>
            <WhyUs />
            <InteractiveMap />
            <StayInKnow />
            <Community />
            <ReadyToTake />
            <Certificates />
            <Brands />
        </>
    );
};

export default Home;