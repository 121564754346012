import React from 'react';
import styles from "./style.module.css";

const ReadyToTake = () => {
    return (
        <section className={styles.readyToTakeSection}>
            <div className={styles.readyToTakeContent}>
                <div className={styles.readyToTakeContentLeft}>
                    <h1 className={styles.title}>Ready to take your<br/> sales processes to<br/> the next level?</h1>
                    <h1 className={styles.mobiletitle}>Ready to take your sales processes to the next level?</h1>
                    <div className={styles.contactUs}>
                        <a className={styles.contactUsBtn}>
                            CONTACT US
                            <span className={styles.arrowspan}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
                                    <path d="M15.5303 7.03033C15.8232 6.73744 15.8232 6.26256 15.5303 5.96967L10.7574 1.1967C10.4645 0.903807 9.98959 0.903807 9.6967 1.1967C9.40381 1.48959 9.40381 1.96447 9.6967 2.25736L13.9393 6.5L9.6967 10.7426C9.40381 11.0355 9.40381 11.5104 9.6967 11.8033C9.98959 12.0962 10.4645 12.0962 10.7574 11.8033L15.5303 7.03033ZM-6.55671e-08 7.25L15 7.25L15 5.75L6.55671e-08 5.75L-6.55671e-08 7.25Z" fill="white"/>
                                </svg>
                            </span>
                        </a>
                    </div>
                </div>
                <div className={styles.readyToTakeContentRight}>
                    <p className={styles.readyToTakeContentRightTitle}>
                        Arrange a free consultation
                    </p>
                    <form>
                        <div className={styles.formBox}>
                            <div className={styles.inputBox}>
                                <label for="name">Your name</label>
                                <input type="text" id="name" name="name" placeholder='Your first name' />
                            </div>
                            <div className={styles.inputBox}>
                                <label for="address">Your email address</label>
                                <input type="email" id="address" name="address" placeholder='yourname@email.com*' />
                            </div>
                            <div className={styles.inputBox}>
                                <label for="phoneNumber">Your email address</label>
                                <input type="phone" id="phoneNumber" name="phoneNumber" placeholder='Your phone number' />
                            </div>
                            <div className={styles.inputBoxCheckbox}>
                                <input type="checkbox" id="PrivacyPolicy" name="PrivacyPolicy" />
                                <label for="PrivacyPolicy">By subscribing to the newsletter, I agree to the TradeBerry Privacy Policy</label>
                            </div>
                            <div>
                                <button className={styles.formButton}>
                                    SEND NOW
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
                                        <path d="M15.5303 7.03033C15.8232 6.73744 15.8232 6.26256 15.5303 5.96967L10.7574 1.1967C10.4645 0.903807 9.98959 0.903807 9.6967 1.1967C9.40381 1.48959 9.40381 1.96447 9.6967 2.25736L13.9393 6.5L9.6967 10.7426C9.40381 11.0355 9.40381 11.5104 9.6967 11.8033C9.98959 12.0962 10.4645 12.0962 10.7574 11.8033L15.5303 7.03033ZM-6.55671e-08 7.25L15 7.25L15 5.75L6.55671e-08 5.75L-6.55671e-08 7.25Z" fill="white"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ReadyToTake;