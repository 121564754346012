import React from 'react';
import styles from "./style.module.css"
const SlideItem = ({itemData}) => {
    return (
        <div className={`${styles.slideItem} `} >
            <div className={styles.slideItemTop}>
                <div className={styles.slideItemTopBox}>
                    <img src={itemData.img} /> 
                </div>
            </div>
            <div className={styles.slideItembody}>
                <h3 className={styles.slideItembodyTitle}>{itemData.title}</h3>
                <div className={styles.slideItemConent}>
                    <p className={styles.slideItemConentTitle}>{itemData.conentTitle}</p>
                    <p className={styles.slideItemConentText}>
                        {itemData.text}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SlideItem;