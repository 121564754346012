import React from 'react';
import styles from "./style.module.css"
import peachElegantLady from "../../assets/peachElegantLady.png";
import peach from "../../assets/peach.png";
import flatPeach from "../../assets/flatPeach.png";
import samantha from "../../assets/Samantha.png";
import apricot from "../../assets/apricot.png";
import nelson from "../../assets/nelson.png";
import plum from "../../assets/plum.png";
import blackDiamond from "../../assets/blackDiamond.png";
import nectarin from "../../assets/nectarin.png";
import amiga from "../../assets/Amiga.png";
import Fruits from './components/Fruits';
import ReadyToTake from '../../components/ReadyToTake/ReadyToTake';


const Products = () => {
    const fruitsArray = [
        {
            id:1,
            name: "Peach",
            sort: "Elegant Lady",
            hectare: "4.9 Hectare",
            three: "10140 Three",
            img:peach,
            sortImg: peachElegantLady
        },
        {
            id:2,
            name: "Flat Peach",
            sort: "Samantha",
            hectare: "4.9 Hectare",
            three: "10140 Three",
            img:flatPeach,
            sortImg: samantha
        },
        {
            id:3,
            name: "Apricot",
            sort: "Nelson",
            hectare: "4.9 Hectare",
            three: "10140 Three",
            img: apricot,
            sortImg: nelson
        },
        {
            id:4,
            name: "Plum",
            sort: "Black Diamond",
            hectare: "4.9 Hectare",
            three: "10140 Three",
            img: plum,
            sortImg: blackDiamond
        },
        {
            id:5,
            name: "Nectarin",
            sort: "Amiga",
            hectare: "4.9 Hectare",
            three: "10140 Three",
            img: nectarin,
            sortImg: amiga
        }
    ]
    return (
        <>
            <section className={styles.productsSection}>
                {/* <div className={styles.content}>
                    <div className={styles.contentAboutInfo} >
                        <div className={styles.contentLeft} >
                            <img src={peach} />
                        </div>
                        <div className={styles.contentRight}>
                            <div className={styles.infoBox}>
                                <div className={styles.rightTop}>
                                <div className={styles.fruitAbout}>
                                        <h1 className={styles.fruit}>Peach</h1>
                                        <h1 className={styles.pruitSort}>Elegant Lady</h1>
                                        <div className={styles.orchardSize}>
                                            <span>4.9 Hectare</span>
                                            <span>10140 Three</span>
                                        </div>
                                </div>
                                    <div className={styles.imgbox} >
                                        <img src={peachElegantLady} />
                                    </div>
                                </div>
                                <p className={styles.descr}>
                                    Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className={styles.productsSectionBody}>
                    {
                        fruitsArray.map(fruit => (
                            <Fruits fruit={fruit} />
                        ))
                    }
                </div>
            </section>
            <ReadyToTake />
        </>
    );
};

export default Products;