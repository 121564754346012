import React, { useRef, useState } from 'react';
import AccordionItem from '../AccordionItem/AccordionItem';
import styles from '../../style.module.css';
const Accordion = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleItemClick = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    const data = [
        {
            question: "Where are TradeBerry's orchards located?",
            answer: 'Our orchards are situated in the picturesque Armavir region of Armenia, benefiting from favorable climatic conditions and nutrient-rich soil.',
        },
        {
            question: ' What fruits does TradeBerry offer?',
            answer: 'TradeBerry offers a diverse array of premium fruits, including apricots, peaches, nectarines, and plums, cultivated with care in the sunny climate of Armenia.',
        },
        {
            question: 'How does TradeBerry ensure fruit quality?',
            answer: 'Meticulous selection of varieties, high cultivation standards, and dedicated nurturing of each tree contribute to the high-quality fruits grown in our orchards.',
        },
        {
            question:
                'How are TradeBerry orchards protected from adverse weather?',
            answer: 'We implement advanced Italian hail protection systems to safeguard our orchards and ensure reliable defense against adverse weather conditions.',
        },
        {
            question:
                'What measures does TradeBerry take for storage and transportation?',
            answer: 'Unique packaging techniques and high-tech refrigerators are employed to preserve taste, aroma, and freshness from our orchards to your shelves.',
        },
        {
            question: 'Can other companies partner with TradeBerry?',
            answer: 'Absolutely! We invite companies and distributors to become our partners in distributing unique Armenian fruits. Contact us to explore collaboration opportunities.',
        },
        {
            question: 'How does TradeBerry preserve fruit freshness?',
            answer: 'Cutting-edge technologies, including Atmosphere Control Station, Ultra-Low Oxygen, Ethylene Converters, and Nitrogen Injection, ensure optimal storage conditions.',
        },
        {
            question: 'What packaging technologies does TradeBerry use?',
            answer: 'Our innovative packaging includes automated distribution systems, vision technology, intelligent weight management, and final quality control, all aimed at preserving the integrity of our fruits.',
        },
        {
            question:
                'How environmentally conscious is TradeBerry in packaging?',
            answer: 'TradeBerry takes responsibility for the environment by using sustainable packaging materials, contributing to a sustainable and responsible approach to production and transportation.',
        },
        {
            question: "What is TradeBerry's contribution to the ecosystem?",
            answer: 'Through our efforts, over 500,000 trees already grace our orchards, shaping a green legacy for future generations and contributing to environmental sustainability. Additionally, another 500,000 trees are currently in the planting stage, further enriching our commitment to environmental stewardship.',
        },
    ];

    return (
        <div className={styles.accordion}>
            {data.map((item, index) => (
                <AccordionItem
                    key={index}
                    question={item.question}
                    answer={item.answer}
                    isOpen={activeIndex === index}
                    onClick={() => handleItemClick(index)}
                />
            ))}
        </div>
    );
};

export default Accordion;
