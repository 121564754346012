import React from 'react';
import styles from "./style.module.css"
import boxImg from "../../../assets/Boreal-Beauty.png"
import { Link } from 'react-router-dom';
const BlogItemBox = ({blogItem}) => {
    return (
        <div className={styles.stayInKnowContentBoxSecond}>
            <div className={styles.stayInKnowTop}>
                <img src={blogItem.img} />
            </div>
            <div className={styles.stayInKnowbottom}>
                <p className={styles.stayInKnowbottomText}>{blogItem.title}</p>
                <div className={styles.stayInKnowbottomContent}>
                    <dov className={styles.readeMoreBox}>
                        <p>{blogItem.date}</p>
                    </dov>
                    {/* <div className={styles.readeMoreBox}>
                        <a>Read more
                            <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none">
                                <circle cx="24.5" cy="24.5" r="24.5" fill="white"/>
                                <path d="M33.3536 24.3536C33.5488 24.1583 33.5488 23.8417 33.3536 23.6464L30.1716 20.4645C29.9763 20.2692 29.6597 20.2692 29.4645 20.4645C29.2692 20.6597 29.2692 20.9763 29.4645 21.1716L32.2929 24L29.4645 26.8284C29.2692 27.0237 29.2692 27.3403 29.4645 27.5355C29.6597 27.7308 29.9763 27.7308 30.1716 27.5355L33.3536 24.3536ZM16 24.5H33V23.5H16V24.5Z" fill="#1D212D"/>
                            </svg>
                        </a>
                    </div> */}
                    <div className={styles.readeMoreBox}>
                        <Link to={`/blogItem/${blogItem.title}`} className={styles.contactUsBtn}>
                            CONTACT US
                            <span className={styles.arrowspan}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
                                    <path d="M15.5303 7.03033C15.8232 6.73744 15.8232 6.26256 15.5303 5.96967L10.7574 1.1967C10.4645 0.903807 9.98959 0.903807 9.6967 1.1967C9.40381 1.48959 9.40381 1.96447 9.6967 2.25736L13.9393 6.5L9.6967 10.7426C9.40381 11.0355 9.40381 11.5104 9.6967 11.8033C9.98959 12.0962 10.4645 12.0962 10.7574 11.8033L15.5303 7.03033ZM-6.55671e-08 7.25L15 7.25L15 5.75L6.55671e-08 5.75L-6.55671e-08 7.25Z" fill="white"/>
                                </svg>
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogItemBox;