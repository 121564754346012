import React, { useEffect, useRef, useState } from 'react';
import styles from '../style.module.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const ScaleContent = ({ brand, initialScale = 1, minScale = 0.5, scaleFactor = 1000, index, brandsLength }) => {
  const [myStyles, setMyStyle] = useState({
    scale: initialScale,
  });

  const componentRef = useRef(null);

  
  return (
    <div
    //   ref={componentRef}
      className={[brand.id === brandsLength && styles.isLastComp, brand.id === 1 && styles.firstComp].join(' ')}
      style={{
        // position: myStyles.scale <= minScale ? 'absolute' : 'relative',
        // top: myStyles.scale <= minScale ? '200px' : '0',
        // opacity: myStyles.scale >= 1 || myStyles.scale <= minScale ? 0 : 1,
        // visibility: myStyles.scale >= 1 || myStyles.scale <= minScale ? 'hidden' : 'visible',
        // transition: 'opacity 0.5s ease-in-out, visibility 0.5s ease-in-out',
      }}
    >
      <div className={styles.ecosystemContent}>
        <div className={styles.leftContent}>
          <img src={brand.img} alt={brand.name} />
        </div>
        <div className={styles.rightContent}>
          <h3 className={styles.brandName}>{brand.name}</h3>
          <div className={styles.brandInfo}>
            <p>
              <span className={styles.infoTitle}>{brand.barndsAbout.title}</span>
              <br />
              {brand.barndsAbout.text}
            </p>
          </div>
          <div className={styles.brandInfo}>
            <p>
              <span className={styles.infoTitle}>{brand.whyUse.title}</span>
              <br />
              {brand.whyUse.text}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScaleContent;
